<template>
  <Browse
    :columns="[
      'date',
      'count_of_items',
      'description',
      'note',
    ]"
    :formats="{
      date: 'Date',
      count_of_items: 'Integer6',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="inflows"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
